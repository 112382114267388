// This is the example interface, as long we are not a typescript we dont have this notion

export class FetcherResponse
{
    constructor(statusCode, data) {
        this.statusCode = statusCode;
        this.data = data;
    }
};

export class StorageAndUsageResponse
{
    constructor(usageResp, storageResp) {
        this.usageResp = usageResp;
        this.storageResp = storageResp;
    }
};

export class IFetcherFramework
{
    constructor(actualImplementation) {
        this.implementor = actualImplementation;
    }

    async FetchFromServer(setNew)
    {
        return await this.implementor.FetchFromServer(setNew);
    }

    async FetchFromServerIfThereIsDiff(setNew)
    {
        return await this.implementor.FetchFromServerIfThereIsDiff(setNew);
    }

    async FetchFromServerIgnoreCache(setNew)
    {
        return await this.implementor.FetchFromServerIgnoreCache(setNew);
    }
};